export default class Query {
  /**
   * 将查询字符串解析成对象
   *
   * @param {string} search 查询字符串
   * @return {Object} 解析后的对象
   */
  static parse(search) {
    search = search || window.location.search;
    search = search[0] === "?" ? search.slice(1) : search;
    const queryArr = search.split("&");
    const query = {};

    queryArr.forEach(function (item) {
      const itemArr = item.split("=");
      if (itemArr.length === 2) {
        query[decodeURIComponent(itemArr[0])] = decodeURIComponent(itemArr[1]);
      }
    });

    return query;
  }

  /**
   * 将查询对象序列化成查询字符串
   *
   * @param {Object} query 查询参数对象
   * @return {string} 查询字符串
   */
  static stringify(query) {
    const queryArr = [];

    for (let key in query) {
      if (query.hasOwnProperty(key)) {
        queryArr.push(`${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`);
      }
    }

    return queryArr.join("&");
  }

  /**
   * 给 URL 拼接查询参数
   *
   * @param {string} url URL 地址
   * @param {Object} query 查询参数对象
   * @return {string} 拼接后的完整 URL
   */
  static joinQuery(url, query) {
    let queryString = this.stringify(query);

    if (!queryString) {
      return url;
    }

    if (url.indexOf("?") === -1) {
      url += "?" + queryString;
    } else {
      url += "&" + queryString;
    }

    return url;
  }

  /**
   * 获取当前页面中的查询字符串对象或特定参数值
   *
   * @param {?string} name 参数名
   * @param {?string} search 待查询的参数字符串
   * @return {Object|string} 查询参数对象/特定参数值
   */
  static getQuery(name, search = window.location.search) {
    const query = this.parse(search);

    if (name) {
      return query[name];
    }

    return query;
  }

  /**
   * 给当前页面设置查询参数
   *
   * @param {Object} query 查询参数对象
   */
  static setQuery(query) {
    let queryString = this.stringify(query);
    if (queryString) {
      if (queryString[0] !== "?") {
        queryString = "?" + queryString;
      }
    } else {
      queryString = window.location.pathname;
    }

    if (window.history.replaceState) {
      window.history.replaceState(null, null, queryString);
    } else {
      window.location.replace(queryString);
    }
  }

  /**
   * 给当前页面拼接查询对象（如存在则覆盖）
   *
   * @param {Object} query 查询参数对象
   */
  static appendQuery(query) {
    const oldQuery = this.getQuery();
    Object.assign(oldQuery, query);
    this.setQuery(oldQuery);
  }

  /**
   * 删除当前页面特定/所有查询参数
   *
   * @param {?string} name 查询参数名
   */
  static removeQuery(name) {
    if (!name) {
      this.setQuery();
    } else {
      const query = this.getQuery();
      delete query[name];
      this.setQuery(query);
    }
  }

  /**
   * 替换当前页面查询参数
   *
   * @param {?string} name 查询参数名
   */
  static replaceQuery(name, query) {
    if (!name) {
      this.setQuery();
    } else {
      const oldQuery = this.getQuery();
      delete oldQuery[name];

      Object.assign(oldQuery, query);
      this.setQuery(oldQuery);
    }
  }

  /**
   * 获取当前页面 hash 中的查询字符串对象或特定参数值
   *
   * @param {?string} name 参数名
   * @return {Object|string} 查询参数对象/特定参数值
   */
  static getHashQuery(name) {
    const query = this.parse(window.location.hash.slice(1));

    if (name) {
      return query[name];
    }

    return query;
  }

  /**
   * 给当前页面 hash 中设置查询参数
   *
   * @param {Object} query 查询参数对象
   */
  static setHashQuery(query) {
    let queryString = this.stringify(query);
    if (queryString[0] !== "#") {
      queryString = "#" + queryString;
    }
    if (window.history.replaceState) {
      window.history.replaceState(null, null, queryString);
    } else {
      window.location.replace(queryString);
    }
  }

  /**
   * 给当前页面 hash 中拼接查询对象（如存在则覆盖）
   *
   * @param {Object} query 查询参数对象
   */
  static appendHashQuery(query) {
    const oldQuery = this.getHashQuery();
    Object.assign(oldQuery, query);
    this.setHashQuery(oldQuery);
  }

  /**
   * 删除当前页面 hash 中特定/所有查询参数
   *
   * @param {?string} name 查询参数名
   */
  static removeHashQuery(name) {
    if (!name) {
      window.location.hash = "";
    } else {
      const query = this.getHashQuery();
      delete query[name];
      this.setHashQuery(query);
    }
  }
}
