// AudioPlayer.js
import { MusicContext } from "@/ MusicContext";
import { useRef, useContext, useEffect, useState } from "react";

import music from "../../public/audio/music.mp3";

const AudioPlayer = () => {
  const { isPlaying } = useContext(MusicContext);
  const audioRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  const [hasInteracted, setHasInteracted] = useState(false);

  useEffect(() => {
    const handleInteraction = () => {
      setHasInteracted(true);
      document.removeEventListener("click", handleInteraction);
    };
    document.addEventListener("click", handleInteraction);
  }, []);

  useEffect(() => {
    if (hasInteracted && isPlaying) {
      audioRef.current.play();
    }
  }, [hasInteracted, isPlaying]);

  return <audio ref={audioRef} src={music} loop autoPlay></audio>;
};

export default AudioPlayer;
