import React, { Component } from 'react'
import FunCommonInput, { EnDataType } from '../../components/common-input';
import FunButton, { EnButtonSize, EnButtonType } from '../../components/fun-button';
import FunLayout, { EnLayoutType } from '../../components/fun-layout';
import Header from '../../components/header';
import CoutDownTime from '../../components/count-down-time';
import Utils from '../../common/utils';
import FunToast from '../../components/fun-toast';
import model from './model';
import { eshiminSetTitleStyle } from '../../common/eshiminUtils';

import './index.scss'
export default class Register extends Component {
  form = {
    name: '',
    mobile: '',
    otp: '',
    pwd: '',
    confirmPwd: '',
  };
  constructor(props) {
    super(props);
    this.state = {
      pwdType: 'password',
      confirmPwdType: 'password',
    };
    this.sendOtpRef = React.createRef();
  }
  componentDidMount() {
    eshiminSetTitleStyle('用户注册');
  }

  /**
   * 发送验证码
   */
  onSendOtp() {
    let { mobile } = this.form;
    let error = Utils.checkMobile(mobile);

    if (!error) {
      model.sendOtp({ mobile }).then(res => {
        console.log('otp:', res);
        this.sendOtpRef.current.startCountTime();
      })
    } else {
      FunToast.show(error);
    }
  }

  onClickRight(field) {
    if (field === 'pwd') {
      this.setState({
        pwdType: this.state.pwdType === 'password' ? '' : 'password',
      });
    } else if (field === 'confirmPwd') {
      this.setState({
        confirmPwdType: this.state.confirmPwdType === 'password' ? '' : 'password',
      });
    }
  }

  onChangeField(field, value) {
    if (field === 'name') {
      this.form.name = value;
    } else if (field === 'mobile') {
      this.form.mobile = value;
    } else if (field === 'otp') {
      this.form.otp = value;
    } else if (field === 'pwd') {
      this.form.pwd = value;
    } else if (field === 'confirmPwd') {
      this.form.confirmPwd = value;
    }
  }

  onCheckParam() {
    const { name, mobile, otp, pwd, confirmPwd } = this.form;
    if (!name) {
      return '请输入真实姓名';
    }
    if (!mobile) {
      return '请输入手机号码';
    }
    const error = Utils.checkMobile(mobile);
    if (error) {
      return error;
    }
    if (!otp) {
      return '请输入验证码';
    }
    if (!pwd) {
      return '请设置密码';
    }
    const pwdError = Utils.checkPassword(pwd);
    if (pwdError) {
      return pwdError;
    }
    if (!confirmPwd) {
      return '请确认密码';
    }
    const confirmPwdError = Utils.checkPassword(confirmPwd);
    if (confirmPwdError) {
      return confirmPwdError;
    }
    if (pwd !== confirmPwd) {
      return '两次输入的密码不一致';
    }
  }

  onUserProtocol() {
    this.props.history.push('/user-protocol');
  }

  onPrivacy() {
    this.props.history.push('/privacy');
  }

  /**
   * 注册
   */
  onRegister() {
    console.log(this.form);
    const error = this.onCheckParam();
    if (error) {
      return FunToast.show(error, 2);
    }
    model.register(this.form).then(() => {
      this.props.history.replace(`/login`);
    })
  }

  render() {
    const { pwdType, confirmPwdType } = this.state;
    return (
      <div className="register-container">
        <FunLayout type={EnLayoutType.fixedLayout} className={'main-content-p'} isAdaptorKeyboard={true}>
          <Header hidden={false} hasBack={true} className={'top'} history={this.props.history}>
            用户注册
          </Header>

          <div className="register-content-container">
            <FunCommonInput
              maxLength={20}
              dataType={EnDataType.none}
              placeholder={'请输入真实姓名'}
              icon={'icon-user'}
              onChange={value => this.onChangeField('name', value)}
            />
            <FunCommonInput
              className={"register-item-container"}
              maxLength={11}
              type={'number'}
              dataType={EnDataType.none}
              placeholder={'请输入手机号'}
              icon={'icon-mobile'}
              onChange={value => this.onChangeField('mobile', value)}
            />
            <div className="register-otp-container">
              <FunCommonInput
                maxLength={6}
                type={'number'}
                className={"register-otp-item-container"}
                dataType={EnDataType.none}
                placeholder={'请输入验证码'}
                onChange={value => this.onChangeField('otp', value)}
              />
              <CoutDownTime
                ref={this.sendOtpRef}
                onClickSend={() => this.onSendOtp()}
                className={'register-send-otp'}
                defaultText={'发送验证码'}></CoutDownTime>
            </div>
            <FunCommonInput
              className={"register-item-container"}
              maxLength={12}
              type={pwdType}
              dataType={EnDataType.none}
              placeholder={'请设置密码'}
              icon={pwdType === 'password' ? 'icon-hide' : 'icon-show'}
              onClickRight={() => this.onClickRight('pwd')}
              onChange={value => this.onChangeField('pwd', value)}
            />
            <FunCommonInput
              className={"register-item-container"}
              maxLength={12}
              type={confirmPwdType}
              dataType={EnDataType.none}
              placeholder={'请确认密码'}
              icon={confirmPwdType === 'password' ? 'icon-hide' : 'icon-show'}
              onClickRight={() => this.onClickRight('confirmPwd')}
              onChange={value => this.onChangeField('confirmPwd', value)}
            />

            <FunButton
              className="register-btn"
              type={EnButtonType.primary}
              size={EnButtonSize.big}
              onClick={this.onRegister.bind(this)}
            >
              注册
            </FunButton>
          </div>

        </FunLayout>
      </div>
    )
  }
}
