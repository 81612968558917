import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FunDynamicCore from '../fun-dynamic-core';
import classNames from 'classnames';
import "./index.scss";

export default class CountDownTime extends Component {
  countTimeFlag = true;
  totalTime = 0;
  currentTime = 0;
  timer = 0;

  againText = '';
  defaultText = '';

  static propTypes = {
    defaultText: PropTypes.string,
    againText: PropTypes.string,
    totalTime: PropTypes.number,
    onClickSend: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    defaultText: '发送验证码',
    againText: '重新发送',
    totalTime: 60,
  };

  constructor(props) {
    super(props);

    this.totalTime = props.totalTime;
    this.againText = props.againText;
    this.defaultText = props.defaultText;

    this.state = {
      text: props.defaultText,
      countTimeFlag: true,
    };
  }

  startCountTime() {
    let lastDate = new Date();

    this.countTimeFlag = false;
    this.currentTime = this.totalTime;
    this.setState({
      text: `(${this.currentTime}s) 重发`,
      countTimeFlag: false,
    });

    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      let dt,
        nowDate = new Date();

      dt = nowDate - lastDate;
      lastDate = nowDate;

      dt = Math.floor(dt / 1000);
      if (dt < 1) {
        dt = 1;
      }

      this.currentTime -= dt;
      this.setState({
        text: `(${this.currentTime}s) 重发`,
      });

      if (this.currentTime < 1) {
        this.completeCountTime();
      }
    }, 1000);
  }

  completeCountTime() {
    this.setState({
      text: this.againText,
      countTimeFlag: true,
    });
    this.currentTime = this.totalTime;
    this.countTimeFlag = true;

    // 清除倒计时
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  onClickText() {
    if (this.countTimeFlag) {
      FunDynamicCore.doCall(this.props.onClickSend);
    }
  }

  render() {
    const { className } = this.props;
    const { text, countTimeFlag } = this.state;
    return (
      <div
        onClick={this.onClickText.bind(this)}
        className={classNames('count-down-time-text', className)}
        style={{ backgroundColor: countTimeFlag ? '#CBB486' : '#808080' }}
      >
        {text}
      </div>
    );
  }
}