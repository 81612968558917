import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.scss';

// 初始化容器
const container = window.document.createElement('div');
container.className = 'fun-loading-container';
window.document.body.appendChild(container);

let isShowing = false;

/**
 * Loading 加载框
 */
export default class Loading extends Component {

  render() {
    let { className, position, text } = this.props;

    return (
      <div className="spinner-container">
        <span className="loading-txt">{text}</span>
        <div className="la-ball-newton-cradle">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

Loading.propTypes = {
  className: PropTypes.string, // 自定义 class
  position: PropTypes.bool, // 是否设置定位。可选值：true - 设置定位（居中显示加载框）；false - 出现在文档流中引入位置
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]) // 显示文本
};

Loading.defaultProps = {
  position: false
};


/**
 * 显示加载框
 * 
 * @param {?text|Object} text 加载框文本字符串或加载框参数对象
 */
Loading.show = function (text) {
  if (isShowing) {
    return;
  }

  isShowing = true;

  let options = { position: true };

  if (text) {
    options.text = text;
  }

  ReactDOM.render(<Loading {...options} />, container);
};


/**
 * 隐藏加载框
 */
Loading.hide = function () {
  if (isShowing) {
    ReactDOM.unmountComponentAtNode(container);
  }

  isShowing = false;
};