import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Query from '../../common/query';
import FunIcon from '../fun-icon';

import './index.scss'

/**
 * 图片组件（支持占位符、加载中、加载失败、懒加载等效果）
 */
export default class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: 'loading'
    };

    this.loadingRef = React.createRef();

    this.loadImg(props.src);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.src !== this.props.src) {
      this.loadImg(nextProps.src);
    }
  }

  componentDidMount() {
    const loadingEl = this.loadingRef.current;

    // 设置加载中占位符图片的大小
    if (loadingEl) {
      const widthRatio = loadingEl.offsetWidth / window.innerWidth;
      const fontsize = Math.ceil(widthRatio * 50);
      const baseFontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
      loadingEl.style.fontSize = baseFontSize ? fontsize / baseFontSize + 'rem' : fontsize + 'px';
    }
  }

  loadImg(src) {
    if (!src) {
      return;
    }

    if (typeof src === 'object' && src[this.props.size]) {
      src = src[this.props.size];
    }

    if (typeof src !== 'string') {
      return;
    }

    if (this.props.autoProcess) {
      src = this.processSrc(src);
    }

    // 地址中可能出现空格（虽然不应该），虽然 img 标签直接加载没问题，但作为 background-image 引用时会有问题，此处统一转码一下
    src = src && src.replace(/ /g, '%20') || '';
    this.src = src;

    let img = new window.Image();
    img.onabort = img.onerror = () => {
      this.setState({ status: 'error' });
    };

    img.onload = () => {
      this.setState({ status: 'success' });
    };

    img.src = src;
  }

  /**
   * 处理阿里云图片（）
   * @param src
   */
  processSrc(src) {
    // 处理阿里云图片，添加压缩参数
    if (src && (src.indexOf('cdn.funplus.cn') > -1) || src.indexOf('oss-cn-shanghai.aliyuncs.com') > -1) {
      let ossParam = Query.getQuery('x-oss-process', src.slice(src.indexOf('?')));
      let newOssParam = ossParam;
      // 没有添加压缩参数，默认添加
      if (!ossParam) {
        src += src.indexOf('?') > -1 ? '&' : '?' + 'x-oss-process=image/resize,w_1125/quality,Q_90';
      } else {
        // 添加 resize 参数
        if (newOssParam.indexOf('/resize,w') === -1) {
          newOssParam += '/resize,w_1125';
        }
        // 添加 quality 参数
        if (newOssParam.indexOf('/quality,Q') === -1) {
          newOssParam += '/quality,Q_90';
        }
        src = src.replace(`x-oss-process=${ossParam}`, `x-oss-process=${newOssParam}`)
      }
    }

    return src;
  }

  render() {
    const { status } = this.state;
    let { className, scale, minScale, maxScale, ...restProps } = this.props;
    let wrapperStyle = {};

    delete restProps.src;
    delete restProps.autoProcess;

    scale = scale || maxScale;
    if (scale) {
      scale = minScale && scale < minScale ? minScale : scale;
      scale = maxScale && scale > maxScale ? maxScale : scale;
      wrapperStyle = { paddingBottom: 1 / scale * 100 + '%' };
    }

    return (
      <div className={classNames('fun-image', className)} style={wrapperStyle} {...restProps}>
        {
          status === 'success'
            ? (
              scale
                ? <div className="scale-img" style={{ height: '100%', backgroundImage: `url('${this.src}')` }} />
                : <img className="origin-img" src={this.src} alt="" />
            )
            : (
              <div className="loading-img" ref={this.loadingRef}>
                <FunIcon icon="icon-mobile" />
              </div>
            )
        }
      </div>
    )
  }
}

Image.propTypes = {
  className: PropTypes.string, // 自定义 class
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // 图片地址
  scale: PropTypes.number, // 图片宽/高缩放比
  minScale: PropTypes.number, // 图片宽/高最小缩放比
  maxScale: PropTypes.number, // 图片宽/高最大缩放比
  autoProcess: PropTypes.bool, // 是否自动处理图片质量
  size: PropTypes.oneOf(['small', 'medium', 'large']) // 图片尺寸类型。可选值：small - 小图、medium - 中图、large - 大图
};

Image.defaultProps = {
  size: 'medium',
  autoProcess: true
};