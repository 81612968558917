import PropTypes from 'prop-types';
import FunDeviceAdaptive, { EnDeviceAdaptiveType } from "../fun-device-adaptive";
import React, { Component } from "react";
import FunDynamicCore from "../fun-dynamic-core";
import "./index.scss";
import FunIcon from '../fun-icon';

export const EnButtonType = {
  default: 'default',
  primary: "primary",
  primary2: "primary2",
  white: "white",
  white2: "white2"
};

export const EnButtonSize = {
  big: 'big',
  small: 'small',
  default: 'default',
  small2: 'small2'
};

export default class FunButton extends Component {

  static propTypes = {
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    adaptiveType: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.any,
    leftIcon: PropTypes.string,
  };

  static defaultProps = {
    color: 'transparent',
    type: EnButtonType.default,
    adaptiveType: EnDeviceAdaptiveType.none,
    size: EnButtonSize.default,
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      isHover: false
    };
  }

  onClick(e) {
    if (!this.props.isDisabled) {
      FunDynamicCore.doCall(this.props.onClick, e);
    }
  }

  render() {
    return (
      <div style={{ display: 'inline' }} className={'fun-button-con'} >
        <ButtonInner {...this.props}>{this.props.children}</ButtonInner>
      </div>
    );
  }
}


class ButtonInner extends Component {

  static propTypes = {
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    adaptiveType: PropTypes.string,
    style: PropTypes.any,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    color: 'transparent',
    type: EnButtonType.default,
    adaptiveType: EnDeviceAdaptiveType.none,
    size: EnButtonSize.default,
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      isHover: false
    };
  }

  onTouchStart() {
    this.setState({
      isHover: true
    });
  }

  onTouchEnd = () => {
    this.setState({
      isHover: false
    });
  };

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    let { className, isDisabled, children, adaptiveType, type, size, style, leftIcon } = this.props;
    className = "fun-button " + className + (isDisabled ? " disabled" : "");

    if (type) {
      className += " " + type;
    }

    if (size) {
      className += " " + size;
    }

    return (
      <div style={style} className={className + (this.state.isHover ? ' hover-btn' : '')}
        onTouchStart={this.onTouchStart.bind(this)}
        onTouchEnd={this.onTouchEnd}
        onTouchMove={this.onTouchEnd}
        onTouchCancel={this.onTouchEnd}>

        {
          this.props.adaptiveType ? (
            <FunDeviceAdaptive type={adaptiveType} onClick={this.onClick.bind(this)}>
              <button className={'inner-btn'}>{children}</button>
            </FunDeviceAdaptive>
          ) :
            <div style={{ display: 'flex', flexDirection: 'row' }} onClick={this.onClick.bind(this)}>
              {leftIcon && <FunIcon icon={leftIcon} />}
              <button>{children}</button>
            </div>
        }
      </div>
    );
  }
}