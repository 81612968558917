import { Fragment } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/login";
import LoginOtp from "./pages/login-otp";
import ForgetPwd from "./pages/forget-pwd";
import Register from "./pages/register";
import PeopleGenerate from "./pages/people-generate";
import AiStudy from "./pages/ai-study";
import SimulationExperience from "./pages/simulation-experience";
import History from "./common/url/history";

import "./public/font/style.css";
import "./public/eshimin.js";
import "./App.scss";
import MusicProvider from "./ MusicContext";
import AudioPlayer from "./pages/audio-player";

function App() {
  return (
    <Fragment>
      <MusicProvider>
        <AudioPlayer /> 
        <HashRouter history={History}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/login-otp" component={LoginOtp} />
            <Route path="/forget-pwd" component={ForgetPwd} />
            <Route path="/register" component={Register} />
            <Route path="/people-generate" component={PeopleGenerate} />
            <Route path="/ai-study" component={AiStudy} />
            <Route
              path="/simulation-experience"
              component={SimulationExperience}
            />
            <Redirect to={"/login-otp"} />
          </Switch>
        </HashRouter>
      </MusicProvider>
    </Fragment>
  );
}

export default App;
