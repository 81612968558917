export const historyList = [{
  year:1921,
  event:"1921年，在兴业路76号的一个石库门寓所，召开全国第一次代表大会"
},{
  year:1922,
  event:"1922年，在上海市老成都北路7弄30号的寓所，召开全国第二次代表大会，旦生第一份党章"
},{
  year:1923,
  event:"1923年，上海市黄浦区古城公园老式街面楼建立上海书店，出版第一份政治机关报《向导》"
},{
  year:1924,
  event:"1924年，在上海市香山路7号，孙中山与李大钊的第一次会晤，开始第一次国共合作"
},{
  year:1925,
  event:"1925年，五卅惨案发生，江南造船所工人举行游行。"
},{
  year:1926,
  event:"1926年，上海工人第一次武装起义。"
},{
  year:1927,
  event:"1927年，南昌起义，共产党创建的人民军队的诞生。"
},{
  year:1928,
  event:"1928年，井冈山会师，开创了工农武装割据的局面，形成农村包围城市的革命道路。"
},{
  year:1929,
  event:"1929年，中共江苏省委第二次代表大会在上海市黄浦区的泉漳中学召开。"
},{
  year:1930,
  event:"1930年，召开的中共六届三中全会，提出“星星之火，可以燎原”。"
},{
  year:1931,
  event:"1931年，通过《中华苏维埃共和国土地法》，按人口或按劳力平均分配土地。"
},{
  year:1932,
  event:"1932年，淞沪抗战开始，中国共产党组织发动群众，支援前线。"
},{
  year:1933,
  event:"1933年，毛泽东率中央政府调查团做调查，《才溪乡调查》提出“没有调查，没有发言权”"
},{
  year:1934,
  event:"1934年，党中央、中央红军8.6万人踏上了战略转移征途，开始“伟大的长征”"
},{
  year:1935,
  event:"1935年，由田汉作词、聂耳作曲，创作《义勇军进行曲》。"
},{
  year:1936,
  event:"1936年，瓦窑堡会议确立了建立抗日民族统一战线的新策略，加强对统一战线工作的领导"
},{
  year:1937,
  event:"1937年，七七事变，又称卢沟桥事变，日军进攻卢沟桥。"
},{
  year:1938,
  event:"1938年，白求恩率援华医疗队来到中国，任八路军晋察冀军区卫生顾问。"
},{
  year:1939,
  event:"1939年，毛泽东提出了“自力更生”和“自己动手”的号召，开始大生产运动。"
},{
  year:1940,
  event:"1940年，陈嘉庚慰劳重庆与延安抗日前线的将士与后方军民。"
},{
  year:1941,
  event:"1941年，“狼牙山五壮士”为掩护根据地群众转移，据险抵抗宁死不屈"
},{
  year:1942,
  event:"1942年，开展整风运动，整顿学风、党风、文风。"
},{
  year:1943,
  event:"1943年，规定选举劳动英雄的范围、条件和方法。"
},{
  year:1944,
  event:"1944年，中外记者西北参观团访问延安，打通向外发声的渠道。"
},{
  year:1945,
  event:"1945年，日本宣布无条件投降，八年抗日战争结束"
},{
  year:1946,
  event:"1946年，内战爆发，全解放区军民动员起来，抗击国民党反动派的军事进攻"
},{
  year:1947,
  event:"1947年，在中国共产党领导下，内蒙古自治区政府宣告成立，结束内蒙古地区长期被分割的历史。"
},{
  year:1948,
  event:"1948年，发布“五一口号”，提出迅速召开新政协，成立民主联合政府。"
},{
  year:1949,
  event:"1949年，中国人民共和国成立！"
},{
  year:1950,
  event:"1950年，通过《中华人民共和国婚姻法》，实行男女婚姻自由、保护妇女和子女的合法利益"
},{
  year:1951,
  event:"1951年，抗美援朝战争进入第二阶段，中朝人民军队执行“持久作战、积极防御”的战略方针。"
},{
  year:1952,
  event:"1952年，新中国的第一条铁成渝铁路正式全线通车。"
},{
  year:1953,
  event:"1953年，开始“一五”计划，国家集中主要力量发展重工业，建设新的工业基地和新兴工业部门。"
},{
  year:1954,
  event:"1954年，第一届全国人民代表大会第一次会议，制定宪法，确定人民代表大会制度"
},{
  year:1955,
  event:"1955年，万隆会议提出亚非各国团结合作和求同存异。"
},{
  year:1956,
  event:"1956年，汉字改革，实现拼音化和简化汉字。"
},{
  year:1957,
  event:"1957年，成立中央防治血吸虫病领导小组，开始消灭血吸虫病。"
},{
  year:1958,
  event:"1958年，第一辆国产轿车试制成功，揭开我国民族轿车工业的历史篇章。"
},{
  year:1959,
  event:"1959年，在第25届世乒赛，容国团拿到新中国的第一个世界冠军"
},{
  year:1960,
  event:"1960年，中国登山队胜利登上海拔8848.48米的珠穆朗玛峰，将五星红旗插在了世界之巅。"
},{
  year:1961,
  event:"1961年，中共中央作出《关于加强原子能工业建设若干问题的决定》，原子核能事业起步。"
},{
  year:1962,
  event:"1962年，首届“百花奖”在北京政协礼堂召开。"
},{
  year:1963,
  event:"1963年，毛泽东发出“向雷锋同志学习”的伟大号召，在全国掀起了“学习雷锋”的高潮。"
},{
  year:1964,
  event:"1964年，学习焦裕禄同志“亲民爱民、艰苦奋斗、科学求实、迎难而上、无私奉献”的精神"
},{
  year:1965,
  event:"1965年，连接浦西和浦东的打浦路隧道，“中国第一隧”正式开工建设。"
},{
  year:1966,
  event:"1966年，首架歼-7在沈阳飞机厂试飞成功。"
},{
  year:1967,
  event:"1967年，中国和坦桑尼亚联合共和国、赞比亚共和国签订关于修建铁路协定，共筑友谊之路。"
},{
  year:1968,
  event:"1968年，南京长江大桥铁路桥通车，由我国独立自主修建的特大桥梁，在当时具有世界先进水平。"
},{
  year:1969,
  event:"1969年，“庆大霉素”正式投产，将我国抗生素研究、生产推向一个崭新阶段。"
},{
  year:1970,
  event:"1970年，第一颗人造地球卫星“东方红一号”发射成功"
},{
  year:1971,
  event:"1971年，第二十六届联合国大会通过恢复中华人民共和国在联合国的一切合法权利。"
},{
  year:1972,
  event:"1972年，美国总统尼克松访华，中美两国政府在上海签署《联合公报》，中美关系正常化。"
},{
  year:1973,
  event:"1973年，经过袁隆平多年艰辛探索，世界第一株籼型杂交水稻在中国培育成功"
},{
  year:1974,
  event:"1974年，世界第八大奇迹兵马俑重见天日。"
},{
  year:1975,
  event:"1975年，中国第一代远洋航天测量船“远望一号”在江南造船厂开工建造"
},{
  year:1976,
  event:"1976年，唐山大地震损失重大，在全国人民和解放军的大力支援下，灾区人民奋起抗灾，重建家园。"
},{
  year:1977,
  event:"1977年，恢复全国高等院校招生考试，采取统一考试、择优录取的办法。"
},{
  year:1978,
  event:"1978年，中国共产党十一届三中全会召开，重新确立“解放思想、实事求是”的思想路线，作出了改革开放的关键抉择。"
},{
  year:1979,
  event:"1979年，《关于把原工商业者中的劳动者区别出来问题的请示报告》的执行是后四十多年个体私营经济壮大的起点。"
},{
  year:1980,
  event:"1980年，在广东省的深圳、珠海、汕头和福建省的厦门四市分别划出一定区域，设置经济特区，引领改革开放事业不断推进。"
},{
  year:1981,
  event:"1981年，第三届世界杯女子排球赛，中国女排首次荣获世界冠军。"
},{
  year:1982,
  event:"1982年，提出“一国两制”科学伟大构想，从而为以和平方式实现祖国统一大业开辟了道路。"
},{
  year:1983,
  event:"1983年，第一辆上海桑塔纳轿车组装下线，由此开创了中国轿车工业的新纪元。"
},{
  year:1984,
  event:"1984年，指出保护和改善生活环境和生态环境，防治污染和自然环境破坏，是我国社会主义现代化建设中的一项基本国策。"
},{
  year:1985,
  event:"1985年，宝山钢铁总厂一期工程建成投产，我国社会主义现代化建设取得的又一重大成就。"
},{
  year:1986,
  event:"1986年，中国翻译出版的《马克思恩格斯全集》中文版五十卷全部出齐。"
},{
  year:1987,
  event:"1987年，国务院批准上海、天津、深圳、广州和海南岛进行土地使用权的有偿转让试点，开启城市建设新思路。"
},{
  year:1988,
  event:"1988年，农业部首次提出建设“菜篮子工程”，保障非谷物食品，尤其是新鲜农产品和肉类的供应与安全。"
},{
  year:1989,
  event:"1989年，以改善贫困地区基础教育设施、救助贫困地区失学少年重返校园为使命的一项公益事业—希望工程，开始启动。"
},{
  year:1990,
  event:"1990年，上海证券交易所正式开业，中国资本市场发展的大幕从此被拉开了。"
},{
  year:1991,
  event:"1991年，国务院批准了国家高新技术产业开发区和有关政策，上海漕河泾新兴技术开发区成为全国首批国家高新技术产业开发区。"
},{
  year:1992,
  event:"1992年，上海市政府批准成立上海市城市建设投资开发总公司 ，探索实施融资建设的新路子。开始上海城市建设快速发展。"
},{
  year:1993,
  event:"1993年，上海外高桥保税区一期工程建成，使中国第一个自由贸易试验区的落地成为现实。"
},{
  year:1994,
  event:"1994年，长江三峡工程正式动工，之后创造了100多项世界之最，创造了建筑史上的奇迹。"
},{
  year:1995,
  event:"1995年，将“两级政府、三级管理”作为深化城区管理体制改革的一项重要步骤，扩大街道管理功能，形成“市—区—街”行政联动。"
},{
  year:1996,
  event:"1996年，国务院对外宣布，要建设以上海为中心、以苏浙为两翼的上海国际航运中心。"
},{
  year:1997,
  event:"1997年，中共中央发布《中国共产党纪律处分条例（试行）》，开始制度治党。"
},{
  year:1998,
  event:"1998年，五里桥街道建立了全市首个社区行政事务受理中心，提高了社区管理与社区办事的效率，方便了社区居民办事。"
},{
  year:1999,
  event:"1999年，中华网在美国纳斯达克上市，互联网时代到来。"
},{
  year:2000,
  event:"2000年，在国家的政策支持下，西部大开发启动，多项重大工程顺利开展，投资环境更加优化，经济社会快速发展。"
},{
  year:2001,
  event:"2001年，中国加入世界贸易组织，我国顺应经济全球化潮流、主动参与国际竞争与合作的积极姿态。"
},{
  year:2002,
  event:"2002年，世界上跨径最大的拱形桥-卢浦大桥主桥合龙。"
},{
  year:2003,
  event:"2003年，我国实现了载人航天的目标，成为世界上第三个具备独立载人航天飞行能力的国家"
},{
  year:2004,
  event:"2004年，进一步加强和改进未成年人思想道德建设"
},{
  year:2005,
  event:"2005年，中国人民银行公布人民币汇率形成机制改革方案，汇率改革"
},{
  year:2006,
  event:"2006年，《关于解决农民工问题的若干意见》，保障农民工权益"
},{
  year:2007,
  event:"2007年，《关于解决城市低收入家庭住房困难的若干意见》，保障住有所居"
},{
  year:2008,
  event:"2008年，第29届夏季奥林匹克运动会开幕"
},{
  year:2009,
  event:"2009年，上海加快发展现代服务业和先进制造业 建设国际金融中心和国际航运中心"
},{
  year:2010,
  event:"2010年，“城市，让生活更美好”，上海世博会开幕"
},{
  year:2011,
  event:"2011年，把文化建设提升到新的历史高度，开始文化兴国新征程"
},{
  year:2012,
  event:"2012年，国家统计局发布数据城镇人口首次超过农村人口，城镇化重要工作是以人为本"
},{
  year:2013,
  event:"2013年，中国（上海）自由贸易试验区正式成立"
},{
  year:2014,
  event:"2014年，第一个国家宪法日，宪法是国家的根本法，是治国安邦的总章程。"
},{
  year:2015,
  event:"2015年，力争通过“三步走”实现制造强国的战略目标"
},{
  year:2016,
  event:"2016年，创新发展网络安全和信息化，走出一条中国特色治网之道"
},{
  year:2017,
  event:"2017年，推动“一带一路”建设行稳致远"
},{
  year:2018,
  event:"2018年，以“新时代，共享未来”为主题的首届中国国际进口博览会开幕"
},{
  year:2019,
  event:"2019年，庆祝中华人民共和国成立70周年"
},{
  year:2020,
  event:"2020年，抗击新冠肺炎，人民至上，为全球公共卫生事业尽责"
}]