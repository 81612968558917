function Map() {
  this.container = new Object();
}
(function () {

  Map.prototype.put = function (key, value) {
    this.container[key] = value;
  }


  Map.prototype.get = function (key) {
    return this.container[key];
  }


  Map.prototype.keySet = function () {
    var keyset = new Array();
    var count = 0;
    for (var key in this.container) {
      if (key == 'extend') {
        continue;
      }
      keyset[count] = key;
      count++;
    }
    return keyset;
  }


  Map.prototype.size = function () {
    var count = 0;
    for (var key in this.container) {
      if (key == 'extend') {
        continue;
      }
      count++;
    }
    return count;
  }


  Map.prototype.remove = function (key) {
    delete this.container[key];
  }


  Map.prototype.toString = function () {
    var str = "";
    for (var i = 0, keys = this.keySet(), len = keys.length; i < len; i++) {
      str = str + keys[i] + "=" + this.container[keys[i]] + ";\n";
    }
    return str;
  }




  function eshimin() {
    this.connectWebViewJavascriptBridge = function (callback) {
      if (window.WebViewJavascriptBridge) {
        callback(WebViewJavascriptBridge)
      } else {
        document.addEventListener(
          'WebViewJavascriptBridgeReady'
          , function () {
            callback(WebViewJavascriptBridge)
          },
          false
        );
      }
    };

    this.connectWebViewJavascriptBridge(function (bridge) {
      bridge.init(function (message, responseCallback) {
        console.log('JS got a message', message);
        var data = {
          'Javascript Responds': 'huidao!'
        };
        console.log('JS responding with', data);
        responseCallback(data);
      });
    })
  };

  eshimin.prototype = {
    ready: function (callback) {

    },
    error: function (callback) {

    },
    closeWindow: function (options) {
      window.WebViewJavascriptBridge.callHandler(
        'closeWindow'
        , ''
        , function (responseData) {
          console.log('closeWindow responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    setTitle: function (options) {
      window.WebViewJavascriptBridge.callHandler(
        'setTitle'
        , encodeURIComponent(options.title)
        , function (responseData) {
          console.log('setTitle responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    setTitleColor: function (options) {
      window.WebViewJavascriptBridge.callHandler(
        'setTitleColor'
        , options.titleColor
        , function (responseData) {
          console.log('setTitleColor responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },


    getNetworkStatus: function (options) {
      window.WebViewJavascriptBridge.callHandler(
        'getNetworkStatus'
        , ''
        , function (responseData) {
          console.log('getNetworkStatus responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    phoneCall: function (options) {
      window.WebViewJavascriptBridge.callHandler(
        'phoneCall'
        , options.num
        , function (responseData) {
          console.log('phoneCall responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },


    getLocalPicture: function (options) {

      window.WebViewJavascriptBridge.callHandler(
        'getLocalPicture'
        , ''
        , function (responseData) {
          console.log('getLocalPicture responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    getPictureByCamera: function (options) {

      window.WebViewJavascriptBridge.callHandler(
        'getPictureByCamera'
        , ''
        , function (responseData) {
          console.log('getPictureByCamera responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },


    getLocation: function (options) {

      window.WebViewJavascriptBridge.callHandler(
        'getLocation'
        , ''
        , function (responseData) {
          console.log('getLocation responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    startShake: function (options) {

      window.WebViewJavascriptBridge.callHandler(
        'startShake'
        , ''
        , function (responseData) {
          console.log('startShake responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },


    getBarcode: function (options) {

      window.WebViewJavascriptBridge.callHandler(
        'getBarcode'
        , ''
        , function (responseData) {
          console.log('getBarcode responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    getBarcodeByAuth: function (options) {
      var string = '{\"uuid\":\"' + options.uuid + '\"}';
      window.WebViewJavascriptBridge.callHandler(
        'getBarcodeByAuth'
        , encodeURIComponent(string)
        , function (responseData) {
          console.log('getBarcodeByAuth responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    /* realPersonAuth: function (options) {
         var string = '{\"clientId\":\"'+options.clientId+'\",'
             +'\"token\":\"'+options.token+'\"}';
         window.WebViewJavascriptBridge.callHandler(
             'realPersonAuth'
             ,encodeURIComponent(string)
             ,function (responseData) {
                 console.log('closeWindow responseData:' + responseData);
                 if(options && options.onResponse){
                     options.onResponse(responseData);
                 }
             }
         );
     },*/
    realPersonAuth: function (options) {
      var string = '{\"clientId\":\"' + options.clientId + '\",' + '\"text\":\"' + options.text + '\",'
        + '\"uniqueId\":\"' + options.uniqueId + '\",'
        + '\"token\":\"' + options.token + '\"}';
      window.WebViewJavascriptBridge.callHandler(
        'realPersonAuth'
        , encodeURIComponent(string)
        , function (responseData) {
          console.log('realPersonAuth responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    share: function (options) {
      var string = '{\"title\":\"' + options.title + '\",'
        + '\"content\":\"' + options.content + '\",'
        + '\"imgUrl\":\"' + options.imgUrl + '\",'
        + '\"link\":\"' + options.link + '\"}';
      window.WebViewJavascriptBridge.callHandler(
        'share', encodeURIComponent(string)
        , function (responseData) {
          console.log('share responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    getTgt: function (options) {
      window.WebViewJavascriptBridge.callHandler('getTgt', '',
        function (responseData) {
          console.log('getTgt responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    showAuthorizedCertificateDetail: function (options) {
      var string = '{\"authorizationCardId\":\"' + options.authorizationCardId + '\",'
        + '\"certUuid\":\"' + options.certUuid + '\"}';
      window.WebViewJavascriptBridge.callHandler('showAuthorizedCertificateDetail', encodeURIComponent(string),
        function (responseData) {
          console.log('showAuthorizedCertificateDetail responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    downloadImageWithBase: function (options) {
      var string = '{\"url\":\"' + options.url + '\"}';
      window.WebViewJavascriptBridge.callHandler('downloadImageWithBase', encodeURIComponent(string),
        function (responseData) {
          console.log('downloadImageWithBase responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    jumpNativePage: function (options) {
      var string = '{\"actionUrl\":\"' + options.actionUrl + '\",'
        + '\"actionType\":\"' + options.actionType + '\",'
        + '\"title\":\"' + options.title + '\",'
        + '\"loginAuthority\":\"' + options.loginAuthority + '\",'
        + '\"authentication\":\"' + options.authentication + '\",'
        + '\"eventId\":\"' + options.eventId + '\"}';
      window.WebViewJavascriptBridge.callHandler('jumpNativePage', encodeURIComponent(string),
        function (responseData) {
          console.log('jumpNativePage responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    getDeviceId: function (options) {
      window.WebViewJavascriptBridge.callHandler('getDeviceId', '',
        function (responseData) {
          console.log('getDeviceId responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    jumpOtherApp: function (options) {
      var string = '{\"scheme\":\"' + options.scheme + '\"}';
      window.WebViewJavascriptBridge.callHandler('jumpOtherApp', encodeURIComponent(string),
        function (responseData) {
          console.log('jumpOtherApp responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    verifyForeignRealPerson: function (options) {
      var string = '{\"verifyType\":\"' + options.verifyType + '\",'
        + '\"name\":\"' + options.name + '\",'
        + '\"idType\":\"' + options.idType + '\",'
        + '\"idNumber\":\"' + options.idNumber + '\",'
        + '\"castgc\":\"' + options.castgc + '\",'
        + '\"nation\":\"' + options.nation + '\"}';
      window.WebViewJavascriptBridge.callHandler('verifyForeignRealPerson', encodeURIComponent(string),
        function (responseData) {
          console.log('verifyForeignRealPerson responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    shSmyYiBaoPay: function (options) {
      window.WebViewJavascriptBridge.callHandler('shSmyYiBaoPay', encodeURIComponent(options.paramDic),
        function (responseData) {
          console.log('shSmyYiBaoPay responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    startRecord: function (options) {
      window.WebViewJavascriptBridge.callHandler('startRecord', '',
        function (responseData) {
          console.log('startRecord responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    translateVoice: function (options) {
      window.WebViewJavascriptBridge.callHandler('translateVoice', '',
        function (responseData) {
          console.log('translateVoice responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    startSpeech: function (options) {
      var string = '{\"speakStr\":\"' + options.speakStr + '\"}';
      window.WebViewJavascriptBridge.callHandler('startSpeech', encodeURIComponent(string),
        function (responseData) {
          console.log('startSpeech responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    stopSpeech: function (options) {
      window.WebViewJavascriptBridge.callHandler('stopSpeech', encodeURIComponent(options.paramDic),
        function (responseData) {
          console.log('stopSpeech responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    getCertificatePicture: function (options) {
      var string = '{\"clientId\":\"' + options.clientId + '\",'
        + '\"certType\":\"' + options.certType + '\"}';
      window.WebViewJavascriptBridge.callHandler(
        'getCertificatePicture'
        , encodeURIComponent(string)
        , function (responseData) {
          console.log('getCertificatePicture responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    // facialIdentification: function (options) {
    //     var string = '{\"clientId\":\"'+options.clientId+'\",'
    //         +'\"certifyUrl\":\"'+options.certifyUrl+'\",'
    //         +'\"certifyId\":\"'+options.certifyId+'\",'
    //         +'\"type\":\"'+options.type+'\"}';
    //     window.WebViewJavascriptBridge.callHandler(
    //         'facialIdentification'
    //         ,encodeURIComponent(string)
    //         ,function (responseData) {
    //             console.log('facialIdentification responseData:' + responseData);
    //             if(options && options.onResponse){
    //                 options.onResponse(responseData);
    //             }
    //         }
    //     );
    // },
    facialIdentification: function (options) {
      var string = '{\"clientId\":\"' + options.clientId + '\",'
        + '\"certifyUrl\":\"' + options.certifyUrl + '\",'
        + '\"certifyId\":\"' + options.certifyId + '\",'
        + '\"text\":\"' + options.text + '\",'
        + '\"type\":\"' + options.type + '\"}';
      window.WebViewJavascriptBridge.callHandler(
        'facialIdentification'
        , encodeURIComponent(string)
        , function (responseData) {
          console.log('facialIdentification responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    smypay: function (options) {
      var string = '{\"channel\":\"' + options.channel + '\",'
        + '\"clientId\":\"' + options.clientId + '\",' + '\"orderStr\":\"' + options.orderStr + '\"}';
      window.WebViewJavascriptBridge.callHandler(
        'smypay'
        , encodeURIComponent(string)
        , function (responseData) {
          console.log('smypay responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    getAppVersion: function (options) {
      window.WebViewJavascriptBridge.callHandler(
        'getAppVersion'
        , ''
        , function (responseData) {
          console.log('getAppVersion responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    getUserStatus: function (options) {
      window.WebViewJavascriptBridge.callHandler(
        'getUserStatus'
        , ''
        , function (responseData) {
          console.log('getUserStatus responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    startSpeech: function (options) {
      var string = '{\"speakStr\":\"' + options.speakStr + '\"}';
      window.WebViewJavascriptBridge.callHandler(
        'startSpeech'
        , encodeURIComponent(string)
        , function (responseData) {
          console.log('startSpeech responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },

    stopSpeech: function (options) {
      window.WebViewJavascriptBridge.callHandler(
        'stopSpeech'
        , ''
        , function (responseData) {
          console.log('stopSpeech responseData:' + responseData);
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    },
    openService: function (options) {
      var params = "{}";
      if (typeof (options) == "object") {
        params = JSON.stringify(options);
      }

      window.WebViewJavascriptBridge.callHandler(
        'openService',
        encodeURIComponent(params),
        function (responseData) {
          if (options && options.onResponse) {
            options.onResponse(responseData);
          }
        }
      );
    }

  };

  window.eshimin = new eshimin();

  if (window.WebViewJavascriptBridge) {
    return;
  }

  var messagingIframe;
  var sendMessageQueue = [];
  var receiveMessageQueue = [];
  var messageHandlers = {};

  var CUSTOM_PROTOCOL_SCHEME = 'yy';
  var QUEUE_HAS_MESSAGE = '__QUEUE_MESSAGE__/';

  var responseCallbacks = {};
  var uniqueId = 1;

  function _createQueueReadyIframe(doc) {
    messagingIframe = doc.createElement('iframe');
    messagingIframe.style.display = 'none';
    var userAgent = navigator.userAgent.toLowerCase();
    doc.documentElement.appendChild(messagingIframe);
  }

  function isAndroid() {
    var ua = navigator.userAgent.toLowerCase();
    var isA = ua.indexOf("android") > -1;
    if (isA) {
      return true;
    }
    return false;
  }

  function isIphone() {
    var ua = navigator.userAgent.toLowerCase();
    var isIph = ua.indexOf("iphone") > -1;
    if (isIph) {
      return true;
    }
    return false;
  }

  //set default messageHandler
  function init(messageHandler) {
    if (WebViewJavascriptBridge._messageHandler) {
      throw new Error('WebViewJavascriptBridge.init called twice');
    }
    WebViewJavascriptBridge._messageHandler = messageHandler;
    var receivedMessages = receiveMessageQueue;
    receiveMessageQueue = null;
    for (var i = 0; i < receivedMessages.length; i++) {
      _dispatchMessageFromNative(receivedMessages[i]);
    }
  }

  function send(data, responseCallback) {
    _doSend({
      data: data
    }, responseCallback);
  }

  function registerHandler(handlerName, handler) {
    messageHandlers[handlerName] = handler;
  }

  function callHandler(handlerName, data, responseCallback) {
    _doSend({
      handlerName: handlerName,
      data: data
    }, responseCallback);
  }

  function _doSend(message, responseCallback) {
    if (responseCallback) {
      var callbackId = 'cb_' + (uniqueId++) + '_' + new Date().getTime();
      responseCallbacks[callbackId] = responseCallback;
      message.callbackId = callbackId;
    }

    sendMessageQueue.push(message);
    messagingIframe.src = CUSTOM_PROTOCOL_SCHEME + '://' + QUEUE_HAS_MESSAGE;
  }

  function _fetchQueue() {
    var messageQueueString = JSON.stringify(sendMessageQueue);
    sendMessageQueue = [];
    if (isIphone()) {
      return messageQueueString;
    } else if (isAndroid()) {
      messagingIframe.src = CUSTOM_PROTOCOL_SCHEME + '://return/_fetchQueue/' + messageQueueString;
    }
  }

  function _dispatchMessageFromNative(messageJSON) {
    setTimeout(function () {
      var message = JSON.parse(messageJSON);
      var responseCallback;
      if (message.responseId) {
        responseCallback = responseCallbacks[message.responseId];
        if (!responseCallback) {
          return;
        }
        responseCallback(message.responseData);
        delete responseCallbacks[message.responseId];
      } else {
        if (message.callbackId) {
          var callbackResponseId = message.callbackId;
          responseCallback = function (responseData) {
            _doSend({
              responseId: callbackResponseId,
              responseData: responseData
            });
          };
        }

        var handler = WebViewJavascriptBridge._messageHandler;
        if (message.handlerName) {
          handler = messageHandlers[message.handlerName];
        }
        try {
          handler(message.data, responseCallback);
        } catch (exception) {
          if (typeof console != 'undefined') {
            console.log("WebViewJavascriptBridge: WARNING: javascript handler threw.", message, exception);
          }
        }
      }
    });
  }

  function _handleMessageFromNative(messageJSON) {
    if (receiveMessageQueue) {
      receiveMessageQueue.push(messageJSON);
    } else {
      _dispatchMessageFromNative(messageJSON);
    }
  }

  var WebViewJavascriptBridge = window.WebViewJavascriptBridge = {
    init: init,
    send: send,
    registerHandler: registerHandler,
    callHandler: callHandler,
    _fetchQueue: _fetchQueue,
    _handleMessageFromNative: _handleMessageFromNative
  };

  var doc = document;
  _createQueueReadyIframe(doc);
  var readyEvent = doc.createEvent('Events');
  readyEvent.initEvent('WebViewJavascriptBridgeReady');
  readyEvent.bridge = WebViewJavascriptBridge;
  doc.dispatchEvent(readyEvent);
})();