import React from 'react';
import { CSSTransition } from 'react-transition-group';
import ReactDOM from 'react-dom';
import './index.scss';
import PropTypes from 'prop-types';
import FunDynamicCore from "../fun-dynamic-core";


/**
 *
 * 使用方法
 *
 * <FunMask ref={this.funMaskRef}></FunMask>
 *
 * // 显示 隐藏
 * this.funMaskRef.current.show();
 * this.funMaskRef.current.hide();
 *
 * 注：无法使用props控制mask 已经被废弃 否则会导致重复渲染
 *
 */
export default class FunToast extends FunDynamicCore {
  static propTypes = {
    message: PropTypes.string,
    time: PropTypes.any,
    isAutoDispose: PropTypes.bool,
    callback: PropTypes.func,
    onHide: PropTypes.func,
  };

  static defaultProps = {
    time: 2,
    isShow: true,
    isAutoDispose: true
  };

  message = "";

  constructor(props) {
    super(props);
    this.message = props.message;
  }

  onClick() {
    if (this.props.isClickBackHide) {
      super.hide();
    }
  }

  show(message = this.message) {
    this.message = message;
    if (super.show(message)) {
      setTimeout(() => {
        super.hide();
        FunDynamicCore.doCall(this.props.callback);
      }, this.props.time * 1000);
    } else {
      this.forceUpdate();
    }
  }

  componentDidMount() {
    super.componentDidMount();
  }

  render() {
    const {
      isShow
    } = this.state;

    return <CSSTransition
      in={isShow}
      timeout={200}
      classNames="_fun-toast"
      unmountOnExit
      onEnter={this.onEnter.bind(this)}
      onExit={this.onExit.bind(this)}
      onExited={this.onExited.bind(this)}
    >
      <div onClick={this.onClick.bind(this)} className="_fun-toast" _fun-toast="true">
        <div className="_fun-toast-content" _fun-toast="true">
          {(this.message && this.message.length >= 20) ? this.message.substring(0, 19) : this.message}
        </div>
      </div>
    </CSSTransition>;
  }

  static instance = null;

  static show(message, time, onHide, callback) {
    let instance;
    if (!FunToast.instance) {
      let container = document.createElement("div"), instance = FunToast.instance = FunDynamicCore.getInstanceRef();
      container.className = "_fun-toast-container";
      document.body.appendChild(container);
      ReactDOM.render(
        <FunToast
          onHide={onHide}
          isAutoDispose={false}
          container={container}
          instance={instance}
          time={time}
          message={message}
          callback={callback} />,
        container
      );
    } else {
      instance = FunToast.instance;
      instance.current.show(message);
    }

    return instance;
  }
}