import React from 'react';
import ReactDOM from 'react-dom';
import Fastclick from 'react-fastclick';
import App from './App';
import FunToast from './components/fun-toast';
import Util from './common/utils';
import './index.scss';

// 禁用系统菜单 比较恶心的浏览器
window.document.oncontextmenu = function (e) {
  e.preventDefault();
};

ReactDOM.render(<App />, document.getElementById('root'));

(function init() {
  // Init fastclick
  Fastclick();

  // 检测是否支持 0.5px 边框
  if (window.devicePixelRatio && devicePixelRatio >= 2) {
    var el = document.createElement('div');
    el.style.border = '.5px solid transparent';
    document.body.appendChild(el);
    if (el.offsetHeight === 1) {
      document.body.classList.add('hairlines');
    }
    document.body.removeChild(el);
  }

  // 不同浏览器各种兼容性问题，此处针对有问题浏览器添加class标识，便于做hack处理（当常规手段无法处理时）
  const ua = window.navigator.userAgent;
  // UC浏览器
  if (/UCBrowser/i.test(ua)) {
    document.body.classList.add('uc-browser');
  }

  if (/SceApp/i.test(ua)) {
    document.body.classList.add('in-app');
  }

  window.addEventListener('orientationchange', function () {
    const orientation = window.orientation || 0;
    if (orientation === 90 || orientation === -90) {
      FunToast.show('竖屏展示体验更好哦');
    }
  });

  //ios版本微信的链接按照首次进入的链接来算，pushState无效，会导致jssdk权限拿不到
  try {
    window.sessionStorage.setItem('url_enter', window.location.href.split('#')[0]);
  } catch (error) { }
})();

/**************************解决ios微信中键盘收起界面不回弹的问题 底部留有空白**********************/
/**************************初步调研结果为 ios会触发 focusin, focusout事件*********************/
(function () {
  let client = Util.getClient();
  if (client.isIos && client.isWx) {
    // 记录位置
    let scrollTop = 0;

    //聚焦的时候记录滚动位置
    window.addEventListener('focusin', function () {
      scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    });

    // 失去焦点的时候回滚 收起键盘
    window.addEventListener('focusout', function () {
      window.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    });
  }
})();
