import { request } from '../../http';

class Model {

  // 校验验证码
  verifyOtp(data) {
    return request(`companyAccount/check`, {
      method: 'POST',
      data,
    });
  }

  // 发送验证码
  sendOtp(data) {
    return request(`index/sendCaptcha`, {
      method: 'POST',
      data,
    });
  }

  // 注册
  register(data) {
    return request(`user/reg`, {
      method: 'POST',
      data,
    });
  }
}

export default new Model();
