/**
 * 获取平台相关的数据，如
 *  是否是 随申办 App 环境
 */

const ua = window.navigator.userAgent || '';
// 是否是 随申办 App 环境
const isApp = /EshiminApp/i.test(ua);

// 是否是微信环境
const isWechat = /MicroMessenger/i.test(ua);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isApp,
  isWechat,
};
