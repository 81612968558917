import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Loading from '../loading';

import './style.scss';

/**
 * 下拉刷新
 */
export default class PullToRefresh extends Component {

  constructor(props) {
    super(props);

    this.state = {
      status: 'deactivate'
    };

    this.isPulling = false;
    this.isRefreshing = false;
    this.selfRef = React.createRef();

    ['onTouchStart', 'onTouchMove', 'onTouchEnd', 'reset'].forEach(method => {
      this[method] = this[method].bind(this);
    });
  }

  componentDidMount() {
    const selfEl = this.selfRef.current;
    const selfHeight = selfEl.offsetHeight;
    selfEl.style.marginTop = `-${selfHeight}px`;

    let { container } = this.props;
    container = container || selfEl.parentElement;

    // Chrome 等浏览器的新版本中，注册 touch、scroll 等事件时，passive 默认值为 true，导致事件回调里调用
    // e.preventDefault 会报错，此处禁用 passive 特性
    const options = this.checkSupportPassive() ? { passive: false } : false;
    container.addEventListener('touchstart', this.onTouchStart, options);
    container.addEventListener('touchmove', this.onTouchMove, options);
    container.addEventListener('touchend', this.onTouchEnd, options);
    container.addEventListener('touchcancel', this.onTouchEnd, options);

    this.container = container;
    this.selfHeight = selfHeight;
  }

  componentWillUnmount() {
    const container = this.container;
    container.addEventListener('touchstart', this.onTouchStart);
    container.addEventListener('touchmove', this.onTouchMove);
    container.addEventListener('touchend', this.onTouchEnd);
    container.addEventListener('touchcancel', this.onTouchEnd);
  }

  onTouchStart(e) {
    // 当前正在刷新时，不可再次刷新
    if (this.isRefreshing) {
      return;
    }

    this.startY = e.targetTouches[0].screenY;
    this.currentY = this.startY;
    this.pulledY = 0;
    this.container.style.transition = 'none';
  }

  onTouchMove(e) {
    const { status } = this.state;
    const { threshold } = this.props;
    const currentY = e.targetTouches[0].screenY;

    // 当前正在刷新时，不可再次刷新
    if (this.isRefreshing) {
      return;
    }

    if (this.startY > currentY) {
      return;
    }

    if (this.isEdge()) {
      // 滚动条从非边缘状态，拖动到边缘状态，才触发下拉刷新拖动的开始，记录开始位置
      if (!this.isPulling) {
        this.startY = currentY;
        this.currentY = currentY;
        this.isPulling = true;
      }

      e.preventDefault();

      const diff = Math.round(currentY - this.currentY);
      this.currentY = currentY;
      this.pulledY += this.damping(diff);

      this.container.style.transform = `translate3D(0, ${this.pulledY}px, 0)`;
    }

    if (this.pulledY < threshold) {
      if (status === 'activate') {
        this.setState({ status: 'deactivate' });
      }
    } else {
      if (status === 'deactivate') {
        this.setState({ status: 'activate' });
      }
    }
  }

  onTouchEnd() {
    // 当前正在刷新时，不可再次刷新
    if (this.isRefreshing) {
      return;
    }

    this.container.style.transition = 'all .3s';

    if (this.state.status === 'activate') {
      this.isRefreshing = true;
      this.setState({ status: 'release' }, () => {
        this.container.style.transform = `translate3D(0, ${this.selfHeight}px, 0)`;
      });

      if (this.props.onRefresh) {
        const result = this.props.onRefresh();
        if (result && result.then) {
          result.then(this.reset, this.reset);
        }
      }
    } else {
      this.pulledY = 0;
      this.container.style.transform = `translate3D(0, 0, 0)`;
    }
  }

  /**
   * 判断浏览器是否支持 passive，使用 passive 特性可增强用户体验
   * （如不判断，直接传对象参数的话，不支持的浏览器中会把第三个参数转成 true）
   */
  checkSupportPassive() {
    var isSupportPassive = false;
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: function () {
          isSupportPassive = true;
        }
      });
      window.addEventListener('test', null, opts);
    } catch (e) { }

    return isSupportPassive;
  }

  /**
   * 是否是在边缘（滚动条在顶部，没有滚动状态。从边缘向下拖动才能触发下拉刷新）
   */
  isEdge() {
    return (document.documentElement.scrollTop || document.body.scrollTop) <= 0;
  }

  /**
   * 阻尼拖动（下拉距离与拖动距离非一一对应，而是逐渐减速下拉）
   * @param {number} diff 
   */
  damping(diff) {
    // 已下拉的距离大于最大可下拉的距离时，不可继续下拉
    if (Math.abs(this.pulledY) > this.props.maxPullDistance && diff > 0) {
      return 0;
    }

    const ratio = Math.abs(this.currentY - this.startY) / window.screen.height;
    const increaseDistance = diff * ((1 - ratio) * 0.6);

    return increaseDistance;
  }

  /**
   *  重置组件状态
   */
  reset() {
    this.isRefreshing = false;
    this.container.style.transform = `translate3D(0, 0, 0)`;

    // 动画结束后更新状态
    setTimeout(() => {
      this.setState({ status: 'deactivate' });
    }, 300);
  }

  render() {
    const { status } = this.state;
    const { className } = this.props;

    return (
      <div className={classNames('fun-pull-to-refresh', className)} ref={this.selfRef}>
        <div className="pull-content">
          {
            status === 'release'
              ? <Loading />
              : <div className={classNames('pull-icon', `${status}-icon`)} />
          }
        </div>
      </div>
    );
  }
}

PullToRefresh.propTypes = {
  className: PropTypes.string, // 自定义class
  threshold: PropTypes.number, // 下拉阀值，超过此值时，可释放触发刷新
  maxPullDistance: PropTypes.number, // 最大下拉距离，超过此值时，将不可下拉
  container: PropTypes.object, // 下拉刷新容器，在此容器内拖动才会触发下拉刷新
  onRefresh: PropTypes.func // 下拉释放后，触发的刷新回调函数
};

PullToRefresh.defaultProps = {
  threshold: 60,
  maxPullDistance: 100
};