import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


import './style.scss';

/**
 * 到底提示
 */
export default class EndTips extends Component {

  render() {
    const { className, text, ...restProps } = this.props;

    return (
      <div className={classNames('fun-end-tips', className)} {...restProps}>
        <span className="tips-line"></span>
        <span>{text}</span>
        <span className="tips-line"></span>
      </div>
    );
  }
}

EndTips.propTypes = {
  className: PropTypes.string, // 自定义 class
  text: PropTypes.string // 提示文本
};

EndTips.defaultProps = {
  text: '亲爱的，到底了'
};