import Image from "@/components/image";
import swiperBg from "../../../public/images/icon-swiper-bg.png";

import "./index.scss";
import classNames from "classnames";
import Utils from "@/common/utils";

export default function SwiperItem(props) {
  console.log(Utils.getUrl(props.url))
  return (
    // <FunSwiper
    //   className="swiper-item-container"
    //   effect={'normal'}
    //   onSwiperItemClick={value => this.onSwiperItemClick(value)}
    //   loop={true}
    // >

    // </FunSwiper>

    <div className={classNames("swiper-item-container", props.className)}>
      <Image className="bg-img" src={swiperBg} />
      <Image className="inner-img" src={Utils.getUrl(props.url)} id={props.id} />

      {/* <div className="swiper-img-container">
      {imgList.map((item, index) => {
        return <Image key={index} src={item.url} />
      })}
    </div> */}
      <div></div>
    </div>
  );
}
