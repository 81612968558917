import SparkMD5 from 'spark-md5';

export class FileMD5 {
  ChunkSize = 2 * 1024 * 1024;

  progress;

  aborted;

  md5(
    file,
    md5Fn,
    progressFn
  ) {
    this.progress = 0;
    this.aborted = false;
    let currentChunk = 0;
    const blobSlice = File.prototype.slice;
    const chunks = Math.ceil(file.size / FileMD5.ChunkSize);
    const spark = new SparkMD5.ArrayBuffer();
    const reader = new FileReader();

    function loadNext() {
      const start = currentChunk * FileMD5.ChunkSize;
      const end = start + FileMD5.ChunkSize >= file.size ? file.size : start + FileMD5.ChunkSize;
      reader.readAsArrayBuffer(blobSlice.call(file, start, end));
    }

    loadNext();

    reader.onloadend = (e) => {
      spark.append(e.target.result); // Append array buffer
      currentChunk += 1;
      this.progress = currentChunk / chunks;

      if (progressFn && typeof progressFn === 'function') {
        progressFn(this.progress);
      }

      if (this.aborted) {
        md5Fn('aborted');
        return;
      }

      if (currentChunk < chunks) {
        loadNext();
      } else {
        md5Fn(null, spark.end());
      }
    };
  }

  abort() {
    this.aborted = true;
  }
}

/**
 * 字符串计算 md5 值
 * @param str 
 * 不能严格唯一，请注意场景
 */
export function hashCode(str) {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;

    // Convert to 32bit integer
    hash &= hash;
  }
  return hash;
}
