/**
 * 创建一个防抖动函数，该函数会在抖动操作停止后的 wait 毫秒后调用 func 方法
 * 如假设需要在滚动事件停止后执行某些操作，可以使用此函数封装滚动回调事件处理函数
 * 
 * @param {Function} func 延迟调用的函数
 * @param {number} wait 延迟调用时间
 * @return {Function} 去除抖动的函数
 */
export default function debounce (func, wait = 200) {
  if (typeof func != 'function') {
    throw new TypeError('Expected a function');
  }
  
  let timeout = null;

  return function () {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(func, wait);
  }
}