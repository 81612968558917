import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 *
 * 字体图标 组件
 * 使用方法：
 * <FunIcon icon="angle-left"></FunIcon>
 *
 */
export default class FunIcon extends Component {
  static propTypes = {
    icon: PropTypes.string,
    iconStyle: PropTypes.object,
    onClick: PropTypes.func,
  };

  static defaultProps = {};

  render() {
    return (
      <span style={this.props.iconStyle} className={`iconfont icon-${this.props.icon}`} onClick={this.props.onClick}></span>
    )
  }
}