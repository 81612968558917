import React, { useEffect, useState } from "react";
import FunLayout, { EnLayoutType } from "@/components/fun-layout";
import Header from "@/components/header";
import { eshiminSetTitleStyle } from "@/common/eshiminUtils";
import "./index.scss";
import Image from "@/components/image";

import historyBg from "../../public/images/icon-history.png";
import startBg from "../../public/images/icon-start.png";
import downArrowImg from "../../public/images/icon-down-arrow.png";
import generateImg from "../../public/images/icon-generate.png";
import FunPicker from "@/components/fun-picker";
import ScrollView from "@/components/scroll-view";
import FunDialog from "@/components/fun-dialog";
import FunToast from "@/components/fun-toast";

const AgeList = [
  { id: 20, name: "20世纪20年代" },
  { id: 30, name: "20世纪30年代" },
  { id: 40, name: "20世纪40年代" },
  { id: 50, name: "20世纪50年代" },
  { id: 60, name: "20世纪60年代" },
];

const FamilyList = [
  { id: 1, name: "出生在南方城市，幼年失去父母" },
  { id: 2, name: "出生在农村，家里有数亩田地" },
  { id: 3, name: "出生在北方城市，父母都在工厂上班" },
];

const HobbyList = [
  { id: 1, name: "聪明好学，喜欢机械" },
  { id: 2, name: "身体健康，活泼好动" },
  { id: 3, name: "生性善良，喜欢帮助他人" },
  { id: 4, name: "不善言辞，坚持忍耐" },
];
const form = { age: "", area: "", habit: "" };

// 解码 Base64 数据
function base64ToString(base64) {
  try {
    const binaryString = atob(base64);
    const utf8Bytes = Uint8Array.from(binaryString, (char) =>
      char.charCodeAt(0)
    );
    return new TextDecoder().decode(utf8Bytes);
  } catch (e) {
    console.error("Failed to decode Base64 string:", e);
    return null;
  }
}

const url = window.location.href;
const tokenMatch = url.match(/params=([^&]+)/);
// console.log("初始值",tokenMatch);
if (tokenMatch) {
  const base64Data = decodeURIComponent(tokenMatch[1]);
  // console.log("decodeURIComponent值",base64Data);
  const decodedData = base64ToString(base64Data);
  // console.log("最后解码值",decodedData);
  document.cookie = decodedData;
}

export default function PeopleGenerate(props) {
  const [age, setAge] = useState("出生年代");
  const [area, setArea] = useState("家庭地域");
  const [habit, setHabit] = useState("性格喜好");
  const [start, setStart] = useState(true);

  const agePickerRef = React.createRef();
  const familyPickerRef = React.createRef();
  const hobbyPickerRef = React.createRef();

  useEffect(() => {
    eshiminSetTitleStyle("登录");
    setTimeout(() => {
      setStart(false)
    }, 8000);
  }, []);

  const onSelectAge = (_, value) => {
    const { id, name } = value;
    form.age = id;
    setAge(name);
  };

  const onSelectFamily = (_, value) => {
    const { id, name } = value;
    form.area = id;
    setArea(name);
  };

  const onSelectHobby = (_, value) => {
    const { id, name } = value;
    form.habit = id;
    setHabit(name);
  };

  const generate = () => {
    const { age, area, habit } = form;
    if (!age) {
      FunToast.show("请选择出生年代");
      return;
    }
    if (!area) {
      FunToast.show("请选择家庭地域");
      return;
    }
    // if (!habit) {
    //   FunToast.show("请选择性格喜好");
    //   return;
    // }
    props.history.replace(
      `./simulation-experience?age=${age}&area=${area}&habit=${habit}`
    );
  };

  return (
    <div className="people-generate-container">
      <FunLayout
        type={EnLayoutType.fixedLayout}
        className={"main-content-p"}
        isAdaptorKeyboard={true}
      >
        <div className="content-container">
          {start ? (
            <div>
              <p className="bg-content" >本次活动涉及故事 感谢五里桥街道“百家百年中国梦”系列故事提供</p>
              <Image
              className="bg-img"
              scale={750 / 1622}
              src={startBg}
              onClick={() => setStart(false)}
            />
            </div>
          ) : (
            <>
              <Image className="bg-img" scale={750 / 1622} src={historyBg} />
              <div className="question-container">
                <div
                  className="question"
                  onClick={() => agePickerRef.current.show()}
                >
                  <div className="content">{age}</div>
                  <Image className="arrow" src={downArrowImg} />
                </div>
                <div
                  className="question"
                  onClick={() => familyPickerRef.current.show()}
                >
                  <div className="content">{area}</div>
                  <Image className="arrow" src={downArrowImg} />
                </div>
                {/* <div
                  className="question"
                  onClick={() => hobbyPickerRef.current.show()}
                >
                  <div className="content">{habit}</div>
                  <Image className="arrow" src={downArrowImg} />
                </div> */}

                <Image
                  className="generate-img"
                  scale={520 / 50}
                  src={generateImg}
                  onClick={generate}
                />
              </div>
            </>
          )}
        </div>
      </FunLayout>

      <FunPicker
        onChange={onSelectAge}
        ref={agePickerRef}
        title=""
        displayFiled="name"
        valueFiled="id"
        defaultValue={age}
        data={[
          {
            options: AgeList,
          },
        ]}
      />

      <FunPicker
        onChange={onSelectFamily}
        ref={familyPickerRef}
        title=""
        displayFiled="name"
        valueFiled="id"
        defaultValue={area}
        data={[
          {
            options: FamilyList,
          },
        ]}
      />

      {/* <FunPicker
        onChange={onSelectHobby}
        ref={hobbyPickerRef}
        title=""
        displayFiled="name"
        valueFiled="id"
        defaultValue={habit}
        data={[
          {
            options: HobbyList,
          },
        ]}
      /> */}
    </div>
  );
}
